import React from 'react'
import { Link } from 'gatsby'
import Lighting from '../../lighting/lighting'
import styles from './navigation.module.css'

export default () => (
	<nav className={styles.navigation}>
		<Link to='/'>Home</Link>
		<Link to='/about'>About</Link>
		<Link to='/articles'>Articles</Link>
		<Lighting />
	</nav>
)
