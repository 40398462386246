import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import styles from './lighting.module.css'

export default () => (
	<ThemeToggler>
		{({ theme, toggleTheme }) => (
			<span className={'material-icons ' + styles.clickable} title={'Toggle to ' + (theme === 'dark' ? 'light' : 'dark') + ' mode'} size="sm" onClick={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')} >{theme === 'dark' ? `light_mode` : `dark_mode`}</span>
		)}
	</ThemeToggler>
)
