import React from 'react'
import { Helmet } from 'react-helmet'
import Navigation from './navigation/navigation'
import Footer from './footer/footer'
import styles from './layout.module.css'

export default ({ children }) => (
	<div className={styles.layoutContainer}>
		<div className={styles.content}>
			<Helmet titleTemplate="%s | Mark Peterson's Blog" defaultTitle="Mark Peterson's Blog">
				<meta charSet="utf-8" />
			</Helmet>
			<div className={styles.container}>
				<Navigation />
				{children}
			</div>
		</div>
		<Footer />
	</div>
)
